import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { Component } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const cardStyle = {
  //   minHeight: 300,
  border: "1px solid #fff",
  boxShadow: "0 0 8px 2px rgba(000, 000,000, 0.3)",
};

const GroupHeaders = {
  groupName: "Group Name: ",
  startDate: "Group Start Date: ",
  numberOfHead: "Number of Head: ",
  breed: "Breed: ",
  sex: "Steers: ",
  age: "Age: ",
  weightRange: "Aprox Weight Range: ",
  penType: "Pen Type: ",
  penSize: "Pen Size: ",
};

export default class DeviceCard extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.state = {
      open: false,
    };
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  render() {
    let links = [];
    let dialog;
    if (this.props.groups.length != 0) {
      {
        this.props.groups.map((v, j) => {
          if (v.deviceId === this.props.deviceId) {
            links.push(
              <Typography
                variant="h5"
                sx={{ mt: 0 }}
                key={j}
                align="left"
                color="text.secondary"
              >
                {v.groupName}
                <CheckCircleIcon
                  key={j}
                  color="info"
                  sx={{ ml: 1 }}
                  fontSize="medium"
                />
              </Typography>
            );

            dialog = (
              <Dialog
                key={j}
                open={this.state.open}
                onClose={this.handleClose}
                fullWidth={true}
                maxWidth={"sm"}
              >
                <DialogTitle key={j} variant="h4" color="text.secondary">
                  {v.deviceName}
                </DialogTitle>
                <Divider />
                <DialogContent>
                  {Object.entries(GroupHeaders).map(([key, value]) => (
                    <>
                      <DialogContentText
                        // key={value}
                        sx={{ fontWeight: "fontWeightHeavy", mt: 1 }}
                      >
                        {value}
                      </DialogContentText>
                      <Typography
                        variant="h6"
                        key={value}
                        sx={{ mt: 0 }}
                        align="left"
                        color="text.primary"
                      >
                        {v[key]}
                        {/* {typeof v[key]} */}
                      </Typography>
                    </>
                  ))}
                </DialogContent>
              </Dialog>
            );
          }
        });
      }
    } else {
      links = [
        <Typography
          variant="h5"
          key={999}
          sx={{ mt: 0 }}
          align="left"
          color="text.secondary"
        >
          No Group Setup
          <ErrorIcon color="error" sx={{ ml: 1 }} fontSize="medium" />
        </Typography>,
      ];
      dialog = (
        <Dialog
          key={999}
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle>Create a New Group</DialogTitle>
          <DialogContent>
            <DialogContentText>
              There is currently no group linked to this machine. Please click
              the create new group button and fill out the group details to link
              a group to your device.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      );
    }

    return (
      <Card style={cardStyle}>
        {dialog}
        <CardActionArea onClick={this.handleOpen}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              align="left"
              color="text.secondary"
              gutterBottom
            >
              Device
            </Typography>
            <Typography variant="h5" align="left" component="div">
              {this.props.deviceName}
            </Typography>

            <Typography sx={{ mb: 0.5 }} align="left" color="text.secondary">
              s/n: {this.props.deviceId.slice(-7)}
            </Typography>
            <Divider />
            <Typography
              sx={{ fontSize: 14, mt: 1.5 }}
              align="left"
              color="text.secondary"
              gutterBottom
            >
              Linked Group
            </Typography>
            {links}
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}
