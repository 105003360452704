export const inputNumeric = (e, callback) => {
  const value = e.target.value ? e.target.value.replace(/[^0-9]+/gi, "") : "";

  if (e.target.value !== value) {
    e.target.value = value;
  }

  if (typeof callback === "function") {
    return callback(value);
  }
};
