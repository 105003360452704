import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Box,
  CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeviceCard from "./DeviceCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

export default class DeviceSection extends Component {
  constructor(props) {
    super(props);
    this.groupchecker = this.groupchecker.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      open: false,
    };
  }

  groupchecker(groups, deviceId) {
    groups.map((val, i) => {
      if (val.deviceId === deviceId) {
        console.log("Match", val);
        return <h1>{val}</h1>;
      } else {
        return <h1>nomatch</h1>;
      }
    });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    return (
      <Grid
        container
        spacing={0}
        rowGap={2}
        columnGap={3}
        direction="row"
        // justify="flex-start"
        alignItems="flex-start"
        style={{
          background: "",
          padding: 0,
          // height: window.innerHeight,
          // width: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          xs={12}
          style={{ background: "", color: "black" }}
          //   alignItems="flex-start"
          justifyContent="flex-start"
        >
          <h3>Devices</h3>
        </Grid>
        {/* This section creates a card for each device */}
        {this.props.deviceList.map((val, i) => {
          var filteredGroups = [];
          this.props.groups.map((val1, i) => {
            if (val1.deviceId == val.deviceId) {
              if (val1.finishDate === undefined || val1.finishDate === null) {
                console.log(
                  "FINISHDATE: ",
                  val1.finishDate,
                  " TYPE: ",
                  typeof val1.finishDate
                );
                filteredGroups.push(val1);
              }
            }
          });
          console.log("Groups: ", this.props.groups);
          return (
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              xs={3}
              key={i}
            >
              <DeviceCard
                groups={filteredGroups}
                deviceId={val.deviceId}
                deviceName={val.deviceName}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}
