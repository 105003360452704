import React, { Component, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import { API, Auth, graphqlOperation } from "aws-amplify";
import * as queries from "../appsync/queries";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import CreateGroupForm from "components/CreateGroupForm";
import EditIcon from "@mui/icons-material/Edit";
import DeviceSection from "components/deviceSection";
import LinearLoading from "components/LinearLoading";
import Divider from "@mui/material/Divider";
import moment from "moment";

// const columns = [
//   { name: "deviceName", header: "Device" },
//   { name: "groupName", header: "Group Name" },
//   { name: "startDate", header: "Group Start Date" },
//   { name: "creationDate", header: "Group Creation Date" },
//   { name: "numberOfHead", header: "Number of Head" },
//   { name: "breed", header: "Breed" },
//   { name: "sex", header: "Sex" },
//   { name: "age", header: "Age" },
//   { name: "weightRange", header: "Weight Range" },
//   { name: "penType", header: "Pen Type" },
//   { name: "penSize", header: "Pen Size" },
//   // { name: "Edit", header: <EditIcon></EditIcon> },
// ];

const columns = [
  { name: "deviceName", header: "Device", defaultFlex: 1 },
  { name: "groupName", header: "Group Name", defaultFlex: 1 },
  { name: "startDate", header: "Group Start Date", defaultFlex: 1 },
  { name: "creationDate", header: "Group Creation Date", defaultFlex: 1 },
  { name: "numberOfHead", header: "Number of Head", defaultFlex: 1 },
  { name: "breed", header: "Breed", defaultFlex: 1 },
  { name: "sex", header: "Sex", defaultFlex: 1 },
  { name: "age", header: "Age", defaultFlex: 1 },
  { name: "weightRange", header: "Weight Range", defaultFlex: 1 },
  { name: "penType", header: "Pen Type", defaultFlex: 1 },
  { name: "penSize", header: "Pen Size", defaultFlex: 1 },
];

const gridStyle = {
  minHeight: 300,
  border: "1px solid #fff",
  boxShadow: "0 0 8px 2px rgba(000, 000,000, 0.3)",
};

class Groups extends Component {
  constructor(props) {
    super(props);
    // this.submitGroup = this.submitGroup.bind(this);
    this.addDefaultData = this.addDefaultData.bind(this);
    this.liftDataRefresh = this.liftDataRefresh.bind(this);
    this.state = {
      groupTable: [],
      groupObject: {},
      deviceList: [],
      groupLoading: true,
    };
  }

  addDefaultData(email, username) {
    console.log("Creating Default Group");
    this.setState((prevState) => ({
      groupObject: {
        ...prevState.groupObject,
        api: "create",
        // deviceId: "",
        email: email,
        username: username,
        // deviceName: "New Device 2",
        timesEdited: 0,
        archived: false,
        deleted: false,
        startDate: moment().valueOf(),
      },
    }));
  }

  componentDidMount() {
    //when the component mounts, get the user's information from cognito
    Auth.currentUserInfo().then((user) => {
      this.addDefaultData(user.attributes.email, user.attributes.sub);

      //after the user's information is retrieved, use it's email attribute to query the API for the embed URL
      API.graphql(
        graphqlOperation(queries.getGroups, {
          username: user.attributes.sub,
          email: user.attributes.email,
          api: "read",
        })
      )
        .then((response) => {
          // console.log("Response --- ", response);
          this.setState({
            groupTable: response.data.getUserGroups.userGroups,
            deviceList: response.data.getUserGroups.userDevices,
            groupLoading: false,
          });
        })
        .catch((error) => console.log(error));
    });
  }

  liftDataRefresh(groupTable) {
    console.log("Lifting Data ARRRGGHHHHHH");
    this.setState({
      groupTable: groupTable,
    });
  }

  render() {
    return (
      <Grid
        container
        spacing={0}
        rowGap={2}
        columnGap={1}
        // disableGutters={false}
        // maxWidth={false}
        style={{
          background: "white",
          padding: 30,
          // height: window.innerHeight,
          // width: "100%",
        }}
      >
        <DeviceSection
          email={this.state.groupObject.email}
          userName={this.state.groupObject.username}
          deviceList={this.state.deviceList}
          groups={this.state.groupTable}
        />

        <Grid
          container
          direction="row"
          xs={12}
          style={{ background: "", color: "black" }}
          //   alignItems="flex-start"
          justifyContent="flex-start"
        >
          <h3>Groups</h3>
        </Grid>

        <Grid item xs={12}>
          <LinearLoading loading={this.state.groupLoading} />
          <ReactDataGrid
            showCellBorders="horizontal"
            // loading={this.state.groupLoading}
            style={gridStyle}
            idProperty="id"
            // style={gridStyle}
            columns={columns}
            dataSource={this.state.groupTable}
          />
        </Grid>
        <Grid
          container
          // xs={12}
          style={{ background: "" }}
          direction="row"
          justifyContent="flex-end"
        >
          <CreateGroupForm
            group={this.state.groupObject}
            devices={this.state.deviceList}
            lift={this.liftDataRefresh}
          ></CreateGroupForm>
        </Grid>
      </Grid>
    );
  }
}

export default Groups;
